.query-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.query-login-logo img{
  margin-top: 5.3333vw;
  width: 17.0667vw;
  height: 17.0667vw;
}

.query-login-title {
  margin-top: 5.3333vw;
  font-weight: 500;
  font-size: 20px;
  color: #262525;
}

.query-login-forms {
  width: 78.6667vw;
  margin-top: 12.8vw;
  display: flex;
  flex-direction: column;
  gap: 6.4vw;
}

.query-login-form {
  display: flex;
  flex-direction: column;
  gap: 3.2vw;
}

.query-login-form label{
  font-size: 16px;
  color: #262525;
}

.query-login-form input{
  height: 11.2vw;
  background: #F2F2F5;
  border-radius: 4px;
  border: 1px solid #E5E6EB;
  padding-left: 3.2vw;
  font-size: 4.2667vw;
}

.query-login-form input::placeholder{
  color: #D2D3D9;
}

.query-login-button {
  margin-top: 43.2vw;
  width: 78.6667vw;
  height: 12.8vw;
  background: #0619B4;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
