.application-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.application-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 12.8vw;
  background: #F2F2F5;
}

.application-progress-currentNumber {
  width: 6.4vw;
  height: 6.4vw;
  background: #0619B4;
  border-radius: 50%;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.application-progress-number {
  width: 6.4vw;
  height: 6.4vw;
  background: rgba(6,25,180,0.1);
  border-radius: 50%;
  color: #0619B4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.application-progress-name {
  color: #262525;
  margin-left: 2.1333vw;
}

.application-progress-line {
  width: 10.6667vw;
  border: 1px dashed #D2D3D9;
  margin-left: 5.3333vw;
  margin-right: 5.3333vw;
}

.application-items {
  margin-top: 2.1333vw;
  margin-bottom: 30vw;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.application-item {
  width: 91.4667vw;
  display: flex;
  flex-direction: column;
}

.application-label {
  margin-top: 5.3333vw;
  margin-bottom: 1.0667vw;
  width: 88.2667vw;
  color: #666;
  font-size: 4.2667vw;
}

.application-input {
  border: none;
  height: 9.6vw;
  border-bottom: 1px solid #E5E6EB;
  font-size: 4vw;
}

.application-select {
  width: 88.2667vw;
  height: 9.6vw;
  font-size: 4vw;
}

.application-input::placeholder {
  color: #CCCCCC;
}

.application-radio {
  display: flex;
  align-items: center;
  gap: 2.1333vw;
  height: 8.5333vw;
  margin-top: 1.3333vw;
}

.application-upload {
  cursor: pointer;
  margin-top: 1.0667vw;
  width: 91.4667vw;
  height: 21.3333vw;
  background: #F2F2F5;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.application-upload img{
  width: 6.4vw;
  height: 6.4vw;
}

.application-upload span{
  font-size: 3.2vw;
  color: #999495;
}

.application-uploadBox {
  display: flex;
  gap: 4px;
}

.application-preview {
  width: auto;
  height: 19.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.application-preview img{
  width: auto;
  height: 100%;
}

.application-download {
  cursor: pointer;
  margin-top: 1.0667vw;
  width: 91.4667vw;
  height: 10.6667vw;
  background: #F2F2F5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.application-download-name {
  display: flex;
  align-items: center;
  margin-left: 3.2vw;
}

.application-download-name img{
  width: 4.2667vw;
  height: 4.2667vw;
}

.application-download-name span{
  margin-left: 1.0667vw;
  font-size: 3.2vw;
  color: #666363;
}

.application-download-button {
  font-size: 12px;
  color: #0619B4;
  margin-right: 3.2vw;
}

.application-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 19.2vw;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.2vw;
  border-top: 1px solid #E5E6EB;
}

.application-nextStep {
  width: 70%;
  height: 12.8vw;
  background: #0619B4;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 4.2667vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.application-previousStep {
  width: 44vw;
  height: 12.8vw;
  background: #E5E6EB;
  border-radius: 4px;
  font-size: 4.2667vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.application-submit {
  width: 44vw;
  height: 12.8vw;
  background: #0619B4;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 4.2667vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
