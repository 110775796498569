.result-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-icon img{
  margin-top: 10.6667vw;
  width: 12.8vw;
  height: 12.8vw;
}

.result-title {
  margin-top: 5.3333vw;
  font-weight: 500;
  font-size: 4.5333vw;
  color: #262525;
}

.result-description {
  margin-top: 2.1333vw;
  width: 74.6667vw;
  color: #999495;
  line-height: 4.8vw;
  text-align: center;
}

.result-info {
  margin-top: 12vw;
  width: 85.0667vw;
  height: auto;
  padding: 5.3333vw;
  box-sizing: border-box;
  background: #F2F2F5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 3.2vw;
}

.result-info-box {
  display: flex;
  flex-direction: column;
}

.result-info-label {
  font-size: 4.2667vw;
  color: #666666;
}

.result-info-content {
  margin-top: 1.0667vw;
  font-size: 4.2667vw;
  color: #262525;
  margin-bottom: 0;
  width: 100%;
  white-space: pre-wrap;
}

.result-button {
  position: fixed;
  bottom: 40.5333vw;
  width: 74.6667vw;
  height: 12.8vw;
  background: #E5E6EB;
  border-radius: 4px;
  color: #262525;
  font-size: 4.2667vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.result-button-primary {
  position: fixed;
  bottom: 40.5333vw;
  width: 74.6667vw;
  height: 12.8vw;
  background: #0619B4;
  border-radius: 4px;
  color: #fff;
  font-size: 4.2667vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.result-button-secondary {
  position: fixed;
  bottom: 24.5333vw;
  width: 74.6667vw;
  height: 12.8vw;
  background: #E5E6EB;
  border-radius: 4px;
  color: #262525;
  font-size: 4.2667vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
